

















































































































import { computed, defineComponent, watch } from "@vue/composition-api";
import { Perawatan } from "../models/perawatan";
import PerawatanFM from "../models/perawatanForm";
import { SaveContext } from "@/apps/core/models/formModel";
import useFormModel from "@/apps/core/modules/useFormModel";

export default defineComponent({
  name: "PerawatanModalForm",
  props: { pasienProp: Object },
  components: {
    DatePicker: () => import("@/apps/core/components/DatePicker.vue"),
    GenericAutocomplete: () =>
      import("@/apps/core/components/GenericAutocomplete.vue"),
    GenericSelect: () => import("@/apps/core/components/GenericSelect.vue"),
  },
  setup(props, { emit }) {
    const requiredFields = [
      "pasien",
      "tanggalMasuk",
      "jenisPerawatan",
      "jenisLokasi",
    ];
    const form = new PerawatanFM(requiredFields, ["kodeBill"]);
    const composition = useFormModel(form);
    const perawatan = composition.instance as Perawatan;
    const formatOption = (opt: Record<string, string>) => {
      opt.nama = opt.no_rm + " - " + opt.nama;
      return opt;
    };
    const jenisPerawatan = computed(() => perawatan.jenisPerawatan);

    watch(jenisPerawatan, (newValue) => {
      composition.errorMap.kodeBill = "";
      perawatan.kodeBill = newValue === "inap" ? null : "000";
    });

    watch(
      () => props.pasienProp,
      (newValue) => {
        const instance = composition.instance as Perawatan;
        if (newValue && newValue.id !== instance.pasien?.id) {
          instance.pasien = newValue;
          composition.validate("pasien");
        }
      },
      { deep: true } // perlu di-deep copy, agar ter-watch!!!
    );

    const saveForm = async () => {
      await composition.formRef.save(SaveContext.Create, false);
      const instance = composition.instance as Perawatan;
      if (instance.id) {
        const obj = { id: instance.id, nama: instance.pasien?.nama };
        emit("close", obj);
      }
    };

    const emitNewPasienEvent = () => emit("newpasien");

    return {
      // Data
      formatOption,

      // Composition
      ...composition,

      // Method
      emitNewPasienEvent,
      saveForm,
    };
  },
});
